.tests {
    animation-name: fadeIn;
    animation-duration: 1s;
    text-decoration-color: white !important;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.nav-link {
    color: white !important;
 
}

.nav-link:hover {
    color: #b86843 !important;
 
}


.test {
    color: #b86843 !important;
 
}